import { GravityPicture } from '../../generated/graphql'

const CDN_PATH_PREFIX = '/images/v2'

const DENSITY_MULTIPLIERS = [1, 2]
const IMAGE_TYPES = {
  'image/webp': {
    queryParam: 'isWebP=true',
  },
  'image/jpeg': {
    queryParam: '',
  },
}

const prepareSource = (url: string) => {
  return url.replace(/https:\/\/(.*?)\/(.*)/, `https://$1${CDN_PATH_PREFIX}/$2`)
}

interface ImageSource {
  url: string
  minWidth: number
  imageWidth: number
  default?: true
  maxDensity?: number
}

const buildSources = (image: {
  alt: string
  sources: ImageSource[]
}): GravityPicture => {
  const { sources, defaultImage } = image.sources.reduce<{
    sources: GravityPicture['sources']
    defaultImage: ImageSource
  }>(
    (sizeAccumulator, image) => {
      if (image.default) {
        sizeAccumulator.defaultImage = image
      }

      if (process.env.ENABLE_IMAGE_RESIZING !== 'true') {
        sizeAccumulator.sources.push({
          sourceSet: image.url || '',
          media: null,
          type: null,
          __typename: 'GravityPictureSource',
        })
        return sizeAccumulator
      }

      const source = prepareSource(image.url || '')

      sizeAccumulator.sources.push(
        ...Object.entries(IMAGE_TYPES).reduce<GravityPicture['sources']>(
          (imageTypeAccumulator, [mimeType, imageType]) => {
            const sourceSet = buildSourceSet(
              source,
              image.imageWidth,
              imageType.queryParam,
              image.maxDensity,
            )
            imageTypeAccumulator.push({
              sourceSet,
              media: `(min-width: ${image.minWidth}px)`,
              type: mimeType,
              __typename: 'GravityPictureSource',
            })
            return imageTypeAccumulator
          },
          [],
        ),
      )
      return sizeAccumulator
    },
    {
      sources: [],
      defaultImage: { imageWidth: 0, minWidth: 0, default: true, url: '' },
    },
  )

  return {
    sources,
    default: defaultImage.url || '',
    alt: image.alt,
    __typename: 'GravityPicture',
  }
}

const buildSourceSet = (
  url: string,
  width: number,
  parameters?: string,
  maxDensity?: number,
) => {
  return DENSITY_MULTIPLIERS.reduce<
    GravityPicture['sources'][0]['sourceSet'][]
  >((densityAccumulator, densityMultiplier) => {
    if (maxDensity && densityMultiplier > maxDensity) return densityAccumulator
    densityAccumulator.push(
      `${url}?width=${width * densityMultiplier}${
        parameters ? `&${parameters}` : ''
      } ${densityMultiplier}x`,
    )
    return densityAccumulator
  }, []).join(', ')
}

export const pictureBuilder = (images: {
  [name: string]: { alt: string; sources: ImageSource[] } | undefined
}) => {
  return Object.entries(images).reduce<{ [name: string]: GravityPicture }>(
    (accumulator, [name, imageSizes]) => {
      if (!imageSizes) {
        return accumulator
      }
      const sources = buildSources(imageSizes)
      accumulator[name] = sources
      return accumulator
    },
    {},
  )
}
